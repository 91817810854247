<template>
  <v-btn color="primary" :to="`/${buyGroup.path}`">
    {{ $t('action:seeProducts') }}
  </v-btn>
</template>

<script>
import I18n from "@/i18n";

export default {
  name: "BuyGroupActions",
  props: ['buyGroup'],
  data: function () {
    const text = {
      seeProducts: "Produits"
    }
    I18n.i18next.addResources("fr", "action", text);
    I18n.i18next.addResources("en", "action", text);
    return {}
  }
}
</script>

<style scoped>

</style>
